import React from 'react'
import Box from '@mui/material/Box'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { FONT_WEIGHT, PreviousStepButton } from '../components'
import {
    useAppliance,
    useAppliances,
    useCreateAppliance,
    useUpdateAppliance,
} from '../../../hooks/use-appliance'
import type { Appliance, ApplianceFormInput } from '../../../types'
import { EnergyClass } from '../../../types'
import { BottomButtons, FormControlWithLabelWrapper, Hint, StepIcon } from './wizard-common'
import CustomButton from '../../../components/custom-button'
import { useDebug } from '../../debug'
import { useAtom } from 'jotai/react'
import { simplifiedAppVersion } from '../../../state/application'

export function WizardStep2({
    nextStep,
    goBack,
    estateId,
    applianceId,
    setApplianceId,
}: {
    estateId: number
    applianceId: number
    nextStep: () => void
    goBack: () => void
    setApplianceId: (applianceId: number) => void
}) {
    const debug = useDebug({ name: 'WizardStep2' })

    //
    // We need estate id for editing appliance mode
    //

    // const {status, data, error, dataUpdatedAt}= useAppliance(applianceId)

    // useEffect(()=>{
    //     debug.log('EFFECT', {status, dataUpdatedAt})
    //     if(data && !error) {
    //         debug.log('EFFECT estateId', data.estate_id)

    //         setEstateId(data.estate_id)
    //     }
    // },[dataUpdatedAt])

    // if (status === 'pending') {
    //     return (
    //         <CircularProgress />
    //     )
    // }

    // if (error) {
    //     return (
    //         <Box>
    //             <ErrorOutlineRoundedIcon />
    //             <Box>{error.message}</Box>
    //         </Box>
    //     )
    // }

    const appliances = useAppliances(estateId)
    debug.logObject('appliances', appliances)
    const { status, error, data } = appliances

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box>
            <StepIcon icon={<DeviceThermostatRoundedIcon />} color={'#DFF2E1'} />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4rem',
                }}
            >
                {
                    // will not show select in case of initial configuration too
                    data && data.length > 0 ? (
                        <ApplianceSelection
                            data={data}
                            applianceId={applianceId}
                            setApplianceId={setApplianceId}
                        />
                    ) : null
                }

                {
                    // allow chaning of appliance
                    applianceId ? (
                        <Edit
                            estateId={estateId}
                            applianceId={applianceId}
                            nextStep={nextStep}
                            goBack={goBack}
                        />
                    ) : (
                        // create new
                        <>
                            {/*I
                            <ApplianceSelection
                                data={data}
                                applianceId={applianceId}
                                setApplianceId={setApplianceId}
                            />
                            */}

                            {/* create new  appliance */}
                            <Box>
                                {
                                    // data === null || applianceId === 0 ?
                                    <New
                                        estateId={estateId}
                                        setApplianceId={setApplianceId}
                                        goBack={goBack}
                                        nextStep={nextStep}
                                    />
                                    // :
                                    // <Edit
                                    //     estateId={estateId}
                                    //     applianceId={applianceId}
                                    //     setApplianceId={setApplianceId}
                                    //     goToStep={goToStep}
                                    //     goBack={goBack}
                                    // />
                                }
                            </Box>
                        </>
                    )
                }
            </Box>
        </Box>
    )
}

function ApplianceSelection({
    data,
    applianceId,
    setApplianceId,
}: {
    data: Appliance[] | null
    applianceId: number
    setApplianceId: (id: number) => void
}) {
    const debug = useDebug({ name: 'ApplianceSelection' })

    const [simplifiedAppVersionValue, setSimplifiedAppVersion] = useAtom(simplifiedAppVersion)

    return (
        <>
            {data != null &&
                // In simple dashboard there's it would confuse user to show multiple appliances if only appliance exists
                (simplifiedAppVersionValue && data.length <= 1 ? false : true) && (
                    <Select
                        // labelId="appliance-energy-class-label"
                        label="Bojler"
                        // id="appliance-energy-class"
                        //{...field}
                        value={applianceId}
                        onChange={event => {
                            const newId = event.target.value as number
                            setApplianceId(newId)
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            Vytvořit nový bojler ...
                        </MenuItem>
                        {(data || []) // data can be null
                            .map(appliance => (
                                <MenuItem key={appliance.id} value={appliance.id}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box>Bojler {appliance.id}</Box>
                                        <Box>
                                            Objem: {appliance.blob.volume} | • Výkon{' '}
                                            {appliance.input_power} W
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))}
                    </Select>
                )}
        </>
    )
}

function New({
    estateId,
    setApplianceId,
    nextStep,
    goBack,
}: {
    estateId: number
    setApplianceId: (applianceId: number) => void
    nextStep: () => void
    goBack?: () => void
}) {
    const debug = useDebug({ name: 'Step 2 new appliance' })
    debug.log('estateId', estateId)

    const defaultValues: ApplianceFormInput = {
        // name: '',
        energyCapacity: '',
        inputPower: 2000,
        isDependentOnHdo: true,
        lossPower: '',
        // blob
        dissipation: '',
        energyClass: EnergyClass.C,
        hotWaterMaxTemperature: '60',
        hotWaterMinTemperature: '40',
        priceWeight: '',
        roomTemperature: '22',
        volume: '150',
        // waterSourceTemperature: '10',  // value is set by backend (#158)
        temperatureWeight: '',
    }
    const mutation = useCreateAppliance(
        estateId,
        false,
        applianceId => {
            // clicked on submit -> success -> ...
            setApplianceId(applianceId)
            //alert(`appliance was created #${applianceId}`)
            nextStep()
        },
        true
    )

    const onSubmit: SubmitHandler<ApplianceFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <Form
            isDisabled={mutation.isPending}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            goBack={goBack}
        />
    )
}

function Edit({
    estateId,
    applianceId,
    nextStep,
    goBack,
}: {
    estateId: number
    applianceId: number
    nextStep: () => void
    goBack?: () => void
}) {
    const debug = useDebug({ name: 'Edit' })

    const appliance = useAppliance(
        applianceId,
        {
            refetchOnMount: false, // @hotfix
        },
        'Edit'
    )
    debug.logObject('appliance', appliance)
    const { status, data, error, dataUpdatedAt } = appliance

    const mutation = useUpdateAppliance(applianceId, estateId, () => {
        nextStep()
    })

    const onSubmit: SubmitHandler<ApplianceFormInput> = data => {
        mutation.mutate(data)
    }

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    const defaultValues: ApplianceFormInput = {
        // name: '',
        energyCapacity: data.energy_capacity ?? '',
        inputPower: data.input_power ?? '',
        isDependentOnHdo: data.is_dependent_on_hdo ?? false,
        lossPower: data.loss_power ?? '',
        // blob
        dissipation: data.blob.dissipation ?? '',
        energyClass: data.blob.energetic_class ?? EnergyClass.C,
        hotWaterMaxTemperature: data.blob.hot_water_max_temperature ?? '',
        hotWaterMinTemperature: data.blob.hot_water_min_temperature ?? '',
        priceWeight: data.blob.price_weight ?? '',
        roomTemperature: data.blob.room_temperature ?? '',
        volume: data.blob.volume ?? '',
        // waterSourceTemperature: data.blob.water_source_temperature ?? '', // value is set by backend (#158)
        temperatureWeight: data.blob.temperature_weight ?? '',
    }

    return (
        <Form
            goBack={goBack}
            onSubmit={onSubmit}
            isDisabled={mutation.isPending}
            defaultValues={defaultValues}
        />
    )
}

function Form({
    defaultValues,
    onSubmit,
    isDisabled,
    goBack,
}: {
    isDisabled: boolean
    onSubmit: SubmitHandler<ApplianceFormInput>
    defaultValues: ApplianceFormInput
    goBack?: () => void
}) {
    const debug = useDebug({ name: 'Form' })

    const { control, handleSubmit } = useForm<ApplianceFormInput>({
        defaultValues,
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3rem',
                }}
            >
                <Box
                    sx={{
                        fontWeight: FONT_WEIGHT.medium,
                        fontSize: '18px',
                        color: '#001F3F',
                    }}
                >
                    <Hint>Informace najdete na štítku bojleru.</Hint>
                </Box>

                <FormControlWithLabelWrapper
                    label={'Jaký objem v litrech má Váš bojler?'}
                    // @todo //                     type={"number"} min={"20"} max={"500"} step={".1"}
                >
                    <Controller
                        name="volume"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="appliance-volume"
                                label="Objem [l]"
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />
                        )}
                    />
                </FormControlWithLabelWrapper>

                <FormControlWithLabelWrapper
                    label={'Jaký má bojler výkon?'}
                    // @todo type={"number"} min={"0.2"} max={"5"} step={".1"}
                >
                    <Controller
                        name="inputPower"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="appliance-volume"
                                label="Výkon bojleru [W]"
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />
                        )}
                    />
                </FormControlWithLabelWrapper>

                {/*

                // value is set by backend (#158)

                <FormControlWithLabelWrapper
                    label={"Jakou máte na bojleru nastavenou teplotu?"}
                    hint={'Teplota musí být minimálně 60°C kvůli legionelle.'}
                // @todo type={"number"} min={"40"} max={"70"} step={"1"}
                >
                    <Controller
                        name="hotWaterMaxTemperature"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                id="appliance-volume"
                                label="Teplota na bojleru [°C]"
                                variant="standard"
                                type="number"
                                required {...field} />
                        }
                    />
                </FormControlWithLabelWrapper>
                */}

                <FormControlWithLabelWrapper
                    label={'Jakou má bojler energetickou třídu?'}
                    hint={'Pokud nevíte, nechte třídu C.'}
                >
                    <Controller
                        name="energyClass"
                        control={control}
                        render={({ field }) => (
                            <Select
                                labelId="appliance-energy-class-label"
                                label="Energetická třída"
                                id="appliance-energy-class"
                                {...field}
                            >
                                {Object.keys(EnergyClass).map(energyClassName => (
                                    <MenuItem key={energyClassName} value={energyClassName}>
                                        {energyClassName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControlWithLabelWrapper>

                <BottomButtons>
                    {goBack ? (
                        /*
                                    <NextStepButton onClick={() => {
                                    // @ts-ignore
                                    mutation.mutate(
                                    step2Data.current.appliance
                                    )
                                    }}>
                                    Pokračovat
                                    </NextStepButton>
                        */

                        <PreviousStepButton
                            onClick={() => {
                                goBack()
                            }}
                        >
                            Zpět
                        </PreviousStepButton>
                    ) : (
                        <Box />
                    )}

                    <CustomButton
                        // text="Vytvořit"
                        text="Pokračovat"
                        type="submit"
                        disabled={isDisabled}
                    />
                </BottomButtons>
            </Box>
        </form>
    )
}
