import React, { type ReactNode, useEffect } from 'react'
import { useAtom } from 'jotai/react'
import { atomWithStorage } from 'jotai/utils'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useEstates } from '../../hooks/use-estate'

const wizardShownForFirstTimeUser = atomWithStorage<boolean>('wizardShownForFirstTimeUser', false)

/*

    USAGE:

    const loadingOrProgress = useRedirectToWizard()
    if(loadingOrProgress) {
        return loadingOrProgress
    }

*/
export function useRedirectToWizard(): { progress: ReactNode | undefined } {
    //
    // open wizard for first-time users ...
    //

    const navigate = useNavigate()
    const [wizardShown, setWizardShown] = useAtom(wizardShownForFirstTimeUser)

    const { status, data, error } = useEstates()

    useEffect(() => {
        if (status === 'success') {
            const estatesAreEmpty = data.length === 0

            if (estatesAreEmpty && !wizardShown) {
                // open once
                setWizardShown(true)
                navigate('/wizard/')
            }
        }
    }, [data])

    let progress : ReactNode

    if (status === 'pending') {
        progress = <CircularProgress />
    }

    if (error) {
        progress = (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return {
        progress: progress,
    }
}
