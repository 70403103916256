import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

// TODO: Need to delete when logging out
export const selectedEstateIdAtom = atomWithStorage<number | null>('selectedEstateId', null)

export const simplifiedAppVersion = atomWithStorage<boolean>('simplifiedAppVersion', true)
export const simplifiedAppVersionConfig = atomWithStorage<{
    selectedEstateId: number,
    selectedApplianceId: number,
    selectedAppliancePlanId: number,
}>('simplifiedAppVersionConfig', {
    selectedEstateId: 0,
    selectedApplianceId: 0,
    selectedAppliancePlanId: 0,
})

export const selectedAppliancePlansAtom = atom<AppliancePlans>({})

interface AppliancePlans {
    [key:string]: number
}
