import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import dayjs from 'dayjs'
import { BarChart } from '@mui/x-charts/BarChart'

import { useSavedAmountPerMonth } from '../hooks/use-estate'

const MONTH_NAMES = [
    'Led',
    'Ún',
    'Bře',
    'Dub',
    'Kvě',
    'Črv',
    'Čvn',
    'Srp',
    'Zář',
    'Říj',
    'List',
    'Pro',
]

function SavedAmountGraph({ estateId }) {
    const saved = useSavedAmountPerMonth(estateId)

    const data = React.useRef<{
        xAxis: string[]
        series: number[]
    }>({
        xAxis: [],
        series: [],
    })

    React.useEffect(() => {
        data.current.xAxis = []
        data.current.series = []
        if (saved.data?.saved_amount_cents) {
            for (let i = 0; i < 12; i++) {
                const item =
                    saved.data.saved_amount_cents[saved.data.saved_amount_cents.length - 1 - i]
                const value: number = item.amount
                const month: string = MONTH_NAMES[dayjs(item.from).month()]

                data.current.xAxis.push(month)
                data.current.series.push(value)
            }
        }
    }, [saved.data?.saved_amount_cents])

    if (saved.status === 'pending') {
        return <CircularProgress />
    }

    if (saved.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{saved.error.message}</Box>
            </Box>
        )
    }

    // BarChart crashes on empty array
    if (data.current.series.length < 1) {
        return <></>
    }

    return (
        <BarChart
            height={300}
            // sx is for <svg> element
            sx={{
                padding: '0 0 0 1.5rem',
                overflow: 'visible', // make yAxis label's text not be cut off
                '& .MuiChartsAxis-label': {
                    transform: 'translate(-16px, 0px)', // yAxis label
                },
            }}
            series={[
                {
                    data: data.current.series, // [1,2,3]
                },
            ]}
            yAxis={[
                {
                    label: 'úspora',
                    // labelStyle: {
                    //     padding: '5px',
                    //     offset: '3px 8px',
                    // },
                },
            ]}
            xAxis={[
                {
                    scaleType: 'band',
                    data: data.current.xAxis, // ['a','b','c'],
                },
            ]}
        />
    )
}
export default SavedAmountGraph
