import React from "react";
import Box from "@mui/material/Box";

import { StepName } from "./wizard/types";
import { useAppliances } from "../hooks/use-appliance";
import { useEstates } from "../hooks/use-estate";

//
// const ENABLE = false;
//

let ENABLE = +(localStorage.getItem('MY_DEBUG_ENABLED'))? true: false; // So we don't need to comment work-in-progress logs anymore

/*
USAGE:

To use, write in Console, for example:

    _DEBUG_VALUE_CALLBACK_ = (a,origValue,c) => {
        console.log('xxx log',a,c,origValue)
        return origValue;
    }

    _DEBUG_VALUE_CALLBACK_ = ( id, origValue, c ) => {
        if( id === 'useAppliance' ) {
            return { ...origValue, refetchOnMount: false }
        } else {
            return origValue
        }
    }

 */
export function debugValue(id, fallbackValue,...args)
{
    if(!ENABLE) return fallbackValue

    // @ts-ignore
    if(window._DEBUG_VALUE_CALLBACK_) {
        // @ts-ignore
        return window._DEBUG_VALUE_CALLBACK_(id, fallbackValue, ...args)
    } else {
        return fallbackValue
    }
}

export function useDebug({
    name='DEBUG',
    backgroundColor = '#00ff0042',
    color = '#000',
}: {
    name?: string,
    backgroundColor?: string,
    color?: string,
}) {

    if(!ENABLE)  {
        return {
            log: () => {},
            logObject: () => {},
        } // value of ENABLE never changes during so this is safe to do
    }

    const PREFIX = [`%c${name}`,`background-color:${backgroundColor};padding:.25rem;color:${color}`]

    // mounting
    React.useEffect(() => {
        console.log(...PREFIX, '+Mounted')
        return () => {
            console.log(...PREFIX, '-UnMounted')
        }
    },[])

    return {
        log: (...args) => console.log(...PREFIX, ...args),
        logObject: (prefix,obj) => {
            try {
                console.log(...PREFIX, prefix, obj, JSON.stringify(obj)) // some objects have dynamic getters ... so create string "snapshot"
            } catch {
                console.log(...PREFIX, prefix,obj)
            }
        }
    }
}

export function DebugWizard({
    currentStep,
    applianceId,
    estateId,
    setEstateId,
    setApplianceId,
    // applianceList,
    // estateList,
    setCurrentStep,
}:{
    currentStep: StepName,
    applianceId:number,
    estateId: number,
    setEstateId: (x:number) => void,
    setApplianceId: (x:number) => void,
    setCurrentStep: (x:StepName) => void,
    // applianceList: any,
    // estateList: any,
}) {

    if(!ENABLE) return null;

    const ALL_STEPS: StepName[] = [
        'step-welcome-0' , 'step1' , 'step2' , 'step2-prepare' , 'step3' , /* 'step4' , 'step5' , 'step6' ,*/ 'step7' , 'step8' , 'step9' /* , 'step10' */
    ]

    const applianceList = useAppliances(estateId)
    const estateList = useEstates()


    return (
        <>
            <Box style={{ outline: '1px solid red', }}>
                <Box>WIZARD !!!</Box>
                <Box>KROK ={currentStep}</Box>
                <Box>estateId ={estateId}</Box>
                <select value={estateId} onChange={(e) => {
                    const id = parseInt(e.target.value) || 0
                    setEstateId(id)
                }}>
                    <option value={0}>---</option>
                    {estateList.data && estateList.data.toSorted((a,b) => a.id - b.id).map((item, index) => (
                        <option value={item.id}
                                key={item.id}>{item.name} #{item.id}</option>
                    ))}
                </select>
                <Box>applianceId ={applianceId}</Box>
                <select value={applianceId} onChange={(e) => {
                    const id = parseInt(e.target.value) || 0
                    console.log("xxx seting", id)
                    setApplianceId(id)
                }}>
                    <option value={0}>---</option>
                    {applianceList.data && applianceList.data.toSorted((a,b) => a.id - b.id).map((item, index) => (
                        <option value={item.id}
                                key={item.id}>appliance {item.created_at} #{item.id}</option>
                    ))}
                </select>


                <Box>step #{currentStep}</Box>
                <select value={currentStep} onChange={(e) => {
                    const stepName = e.target.value as StepName
                    setCurrentStep(stepName)
                }}>
                    <option value={0}>---</option>
                    {
                        ALL_STEPS.map((step: StepName, index) => (
                        <option value={step}
                                key={step}>{step}</option>
                    ))}
                </select>
            </Box>
            {/* some space from debug box */}
            <Box sx={{ height: '40px' }}/>
        </>
    )
}
