import React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import type { SxProps } from '@mui/material/styles'

import { FONT_WEIGHT } from '../components'

export function TODO({ children }) {
    return <Box sx={{ outline: '1px solid red' }}>{children}</Box>
}

export function DebugSpecification({ children }) {
    return (
        <Box
            sx={{
                outline: '1px solid green',
            }}
        >
            <i>Specifikace:</i>
            <pre>{children.trim()}</pre>
        </Box>
    )
}

export function DebugDataSource({ children }) {
    return <Box sx={{ color: '#000', backgroundColor: '#eee' }}>{children}</Box>
}

export function DebugInputEvents(props: {
    selector: string
    onChange: any
    children: React.ReactNode
}) {
    const [n, setN] = React.useState(0)

    // biome-ignore lint/correctness/useExhaustiveDependencies: runs only on load, once
    React.useEffect(() => {
        const list = Array.from(document.querySelectorAll(props.selector) || [])

        setN(list.length)

        list.forEach(node =>
            node.addEventListener('change', (x, y) => {
                props.onChange(x, y)
            })
        )
    }, [])

    return (
        <>
            <Box sx={{ outline: '1px solid orange' }}>
                found #{n} items for selector '{props.selector}'
            </Box>
            {props.children}
        </>
    )
}

export function UnfortunatelyWeCantContinue({ children }) {
    return <Box sx={{ color: 'red' }}>{children}</Box>
}

export function Debug({ children }) {
    return (
        <Box
            sx={{
                outline: '1px solid red',
            }}
        >
            {children}
        </Box>
    )
}

export function DebugFixme({ who, children }) {
    return (
        <Box sx={{ outline: '2px solid red' }}>
            <Box sx={{ backgroundColor: 'red', color: '#fff' }}>FIXME: {who}</Box>
            {children}
        </Box>
    )
}

function Label({
    id,
    sx,
    component,
    children,
}: { id: string; sx; children?: React.ReactNode; component: 'label' | 'h2' }) {
    if (component === 'label') {
        return (
            <InputLabel id={id} sx={sx}>
                {children}
            </InputLabel>
        )
    }

    return (
        <Typography component="h2" id={id} sx={sx}>
            {children}
        </Typography>
    )
}

export function FormControlWithLabelWrapper({
    label,
    hint,
    children,
    isHeader,
    component = 'label',
}: {
    label: string
    hint?: React.ReactNode
    children?: React.ReactNode
    isHeader?: boolean
    component?: 'label' | 'h2'
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.75rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.75rem',
                }}
            >
                <Label
                    component={component}
                    id={label}
                    sx={{
                        display: 'flex',
                        color: '#001F3F',
                        fontSize: isHeader ? '20px' : '18px',
                        fontWeight: FONT_WEIGHT.medium,
                        whiteSpace: 'normal',
                        // borderBottom: underline? '1px solid' : undefined,
                        ...(isHeader
                            ? {
                                  margin: '0 0 27px 0',
                                  /* border-bottom: 1px dashed; */
                                  justifyContent: 'center',
                                  padding: '15px 0 11px 0',
                              }
                            : {}),
                    }}
                >
                    {label}
                </Label>
                {hint && <Hint>{hint}</Hint>}
            </Box>
            {children}
        </Box>
    )
}

export function BottomButtons({ children }) {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {children}
            </Box>

            <Box sx={{ height: '50px' }} />
        </>
    )
}

export const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
        display: flex;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 1rem;
        width: 100%;
        border: none;
        resize: vertical;
        outline: 1px solid #001f3f;
        transition: outline-width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; /* copied from mui input transition */
        
        &:hover {
            outline-width: 2px;
        }
    
        &:focus {
            outline-width: 2px;
            outline-color: #001f3f;
        }
    
        // firefox
        &:focus-visible {
          /* outline: 0; */
        }
  `
)

export function Hint({
    children,
    sx,
}: {
    children?: React.ReactNode
    sx?: SxProps<Theme>
}) {
    return (
        <Box
            sx={{
                color: '#81909F',
                fontSize: '13px',
            }}
        >
            {children}
        </Box>
    )
}

export function StepIcon({
    icon,
    color = undefined,
}: {
    icon: React.ReactNode
    color?: string
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                // margin: '0 0 1.75rem 0',
                margin: '2rem 0px 1.25rem',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: color || '#F0F0F0',
                    width: '3.25rem',
                    aspectRatio: '1',
                    borderRadius: '50%',
                    '&>svg': {
                        fontSize: '2rem',
                    },
                }}
            >
                {icon}
            </Box>
        </Box>
    )
}

export function Text({
    children,
    color = undefined,
    isHeader = false,
    sx = {},
}: {
    children: React.ReactNode
    isHeader?: boolean
    color?: string
    sx?: object
}) {
    return (
        <Box
            sx={{
                ...(isHeader
                    ? {
                          color: color || '#001F3F',
                          fontSize: '20px',
                          fontWeight: FONT_WEIGHT.medium,
                          whiteSpace: 'normal',
                          // borderBottom: underline? '1px solid' : undefined,
                          margin: '0 0 27px 0',
                          /* border-bottom: 1px dashed; */
                          justifyContent: 'center',
                          padding: '15px 0 11px 0',
                      }
                    : {
                          display: 'flex',
                          color: color || '#001F3F',
                          fontSize: '18px',
                          fontWeight: FONT_WEIGHT.medium,
                      }),
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}
