import React from 'react'
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";

import { FONT_WEIGHT, PreviousStepButton } from "../components";
import {
    useCreateEstate,
    useEstate,
    useEstates,
    useUpdateEstate
} from "../../../hooks/use-estate";
import type { EstateFormInput } from "../../../types";
import { BottomButtons, FormControlWithLabelWrapper, StepIcon } from "./wizard-common";
import CustomButton from "../../../components/custom-button";

export function WizardStep1({
    nextStep,
    setEstateId,
    estateId,
    goBack,
}: {
    nextStep: () => void,
    setEstateId: (id: number) => void,
    estateId: number,
    goBack: () => void;
}) {

    const estateList = useEstates()

    // function goToNextStep(estateId: number) {
    //     setEstateId(estateId)
    //     nextStep()
    // }

    if (estateList.status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (estateList.error || estateList.data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{estateList.error?.message}</Box>
            </Box>
        )
    }

    return (
        <Box>

            <StepIcon icon={<FlagRoundedIcon />} />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4rem'
            }}>
                {/*
                estateList.data.length > 0 &&
                    <Select
                        // labelId="appliance-energy-class-label"
                        label="Nemovitost"
                        // id="appliance-energy-class"
                        //{...field}
                        value={estateId}
                        onChange={(event) => {
                            const newEstateId = event.target.value as number
                            setEstateId(newEstateId)
                        }}
                    >
                        <MenuItem key={0} value={0}>Vytvořit novou nemovitost ...</MenuItem>
                        {estateList.data.map(estate =>
                            <MenuItem
                                key={estate.id}
                                value={estate.id}
                            >
                                {estate.name}
                            </MenuItem>
                        )}
                    </Select>

                */}

                {
                    estateId === 0 ?
                        <New
                            nextStep={nextStep}
                            setEstateId={setEstateId}
                            goBack={goBack}
                        />
                        :
                        <Existing // to change name ...
                            nextStep={nextStep}
                            estateId={estateId}
                            goBack={goBack}
                        />
                }
            </Box>
        </Box>
    )
}

function New({
    nextStep,
    setEstateId,
    goBack,
}: {
    nextStep: () => void;
    setEstateId: (x: number) => void;
    goBack: () => void;
}) {
    const defaultValues: EstateFormInput = {
        name: '',
        latitude: '0', // must be a number
        longitude: '0', // must be a number
    }

    const [isSmartPlug, setIsSmartPlug] = React.useState<boolean | undefined>(true)
    const [isHeaterPlugged, setIsHeaterPlugged] = React.useState<boolean | undefined>(true)

    const mutation = useCreateEstate(false, (newEstate) => {
        // clicked on continue button -> successfully created estate -> ...
        // alert(`Created new estate #${newEstate.id}`)
        setEstateId(newEstate.id)
        nextStep()
    });

    const onSubmit: SubmitHandler<EstateFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <>
            {/*

            <SelectWithError
                title={'Máte bojler, který je zapojen do zásuvky?'}
                onChange={(event) => {
                    //console.log("XXX select", event.target.value)
                    const value = event.target.value === '1';
                    setIsHeaterPlugged(value)
                }}
                options={[
                    { name: 'Ano', value: '1' },
                    { name: 'Ne', value: '0' }
                ]}
                hasError={isHeaterPlugged === false}
                errorText={`Je nám líto, ale v současné chvíli je možné optimalizovat pouze bojlery zapojené do zásuvky.`}
            />


            true && // isHeaterPlugged &&
                <SelectWithError
                    title={'Vyberte typ chytré zásuvky, kterou jste zakoupili.'}
                    onChange={(event) => {
                        //console.log("XXX select", event.target.value)
                        const value = event.target.value === '1';
                        setIsSmartPlug(value)
                    }}
                    options={[
                        { name: "SHELLY-PLUG-16A", value: '1' },
                        {
                            name: "Nemám zakoupenou žádnou z těchto chytrých zásuvek.",
                            value: '2'
                        }
                    ]}
                    hasError={isSmartPlug === false}
                    errorText={`Pro optimalizaci aplikací. Chytrý Bojler je nutné zakoupit jednu z těchto chytrých zásuvek.`}
                />
            
            */}

            {true && // isHeaterPlugged && isSmartPlug &&
                <Form
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    isDisabled={(
                           !isHeaterPlugged 
                        || !isSmartPlug
                        || mutation.isPending // loading data
                    )}
                    goBack={goBack}
                />
            }

            {mutation.isError ? (
                <div>Došlo k
                    chybě: {mutation.error.message}</div>
            ) : null}
        </>
    )
}

function Existing({
    estateId,
    nextStep,
    goBack
}: {
    estateId: number;
    nextStep: () => void;
    goBack: () => void;
}) {

    const { status, data, error } = useEstate(estateId)

    const mutation = useUpdateEstate(estateId, () => {
        nextStep()
    })

    const onSubmit: SubmitHandler<EstateFormInput> = data => {
        mutation.mutate(data)
    }

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    const defaultValues: EstateFormInput = {
        name: data.name,
        longitude: data.longitude, // required
        latitude: data.latitude,
    }

    return (
        <>
            <Form
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                isDisabled={mutation.isPending}
                goBack={goBack}
            />

            {mutation.isError ? (
                <div>Došlo k
                    chybě: {mutation.error.message}</div>
            ) : null}
        </>
    )
}

function Form({
    defaultValues,
    onSubmit,
    isDisabled,
    goBack,
}: {
    isDisabled: boolean;
    onSubmit: SubmitHandler<EstateFormInput>;
    defaultValues: EstateFormInput;
    goBack: () => void;
}) {
    const { control, handleSubmit } = useForm<EstateFormInput>({
        defaultValues
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                gap: '4rem',
                flexDirection: 'column',
            }}>
                <FormControlWithLabelWrapper
                    label={'Jméno nemovitosti'} // 'Jak se bude jmenovat Vaše nemovitost?'
                >
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField
                            id="estate-name"
                            //label="Název nemovitosti"
                            variant="standard"
                            required {...field} />}
                    />
                </FormControlWithLabelWrapper>

                <BottomButtons>                     
                    <PreviousStepButton
                        onClick={() => {
                            goBack()
                        }}
                    >
                        Zpět
                    </PreviousStepButton>

                    <CustomButton
                        text="Pokračovat"
                        type="submit"
                        disabled={isDisabled}
                    />
                </BottomButtons>
            </Box>
        </form>
    )
}

function ErrorComponent({ children }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '.75rem',
            backgroundColor: '#F4C6BB',
            color: '#313131',
            fontSize: '1rem',
            lineHeight: '19px',
            padding: '.75rem 1.25rem',
            borderRadius: '20px',

        }}>
            <ErrorOutlineRoundedIcon />
            <Box>
                {children}
            </Box>
        </Box>
    )
}

function SelectWithError({
    title,
    options,
    onChange,
    hasError,
    errorText,
}: {
    title: React.ReactNode,
    errorText?: string,
    options: { name: string, value: string }[],
    hasError: boolean,
    onChange: (event: SelectChangeEvent) => void,
}) {
    const label = React.useId() // @todo make proper label

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.75rem',
        }}>
            <InputLabel id={label} sx={{
                display: 'flex',
                color: '#001F3F',
                fontSize: '18px',
                fontWeight: FONT_WEIGHT.medium,
            }}>{title}</InputLabel>
            <FormControl
                fullWidth variant="standard"
                sx={{ minWidth: 130, gap: '1.5rem' }}
                error={hasError}
            >
                <Select
                    labelId={label}
                    //id="appliance-energy-class"
                    onChange={onChange}
                >
                    {options.map(({ name, value }) => (
                        <MenuItem key={value} value={value}>{name}</MenuItem>
                    ))}
                </Select>
                {
                    hasError &&
                    <Error>{errorText}</Error>
                }
            </FormControl>
        </Box>
    )
}
