import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import UpdateRenewableSourceForm from '../components/forms/renewable-source/update'
import DeleteRenewableSourceForm from '../components/forms/renewable-source/delete'
import useDrawer from '../hooks/use-drawer'
import SwipeableTemporaryDrawer from '../components/swipeable-temporary-drawer'
import { useRenewableSource } from '../hooks/use-renewable-source'
import { useRedirectToSimpleHomepageIfEnabled } from '../hooks/use-simple-homepage'

function RenewableSourceView() {

    useRedirectToSimpleHomepageIfEnabled()

    let { renewableSourceId } = useParams()

    const [drawerOpenSettings, toggleDrawerSettings, resetDrawerSettings] = useDrawer()

    const { status, data, error } = useRenewableSource(renewableSourceId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }
    
    return (
        <>
            <Container maxWidth="sm">
                <Box display="flex" alignContent="center">
                    <Box marginLeft={-1}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            component={Link}
                            to="/"
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 1,
                    }}>
                        {data.name ?? 'Fotovoltaika '+data.id}
                    </Box>
                    <Box>
                        <IconButton
                            color="primary"
                            aria-label="settings"
                            onClick={toggleDrawerSettings(true)}
                        >
                            <SettingsRoundedIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1rem',
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    ID
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    {data.id}
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Sklon panelů
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    {data.declination}°
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Umístění panelů
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    {data.azimuth}°
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Celkový výkon
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    {data.power} W
                </Box>
            </Container>
            <SwipeableTemporaryDrawer
                open={drawerOpenSettings}
                toggleDrawer={toggleDrawerSettings}
            >
                <UpdateRenewableSourceForm renewableSource={data} resetDrawer={resetDrawerSettings}/>
                <DeleteRenewableSourceForm renewableSourceId={data.id} estateId={data.estate_id}/>
            </SwipeableTemporaryDrawer>
        </>
    )
}

export default RenewableSourceView
